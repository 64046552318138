﻿/// <reference path = "../main.scss" />
// File Name: _variables.scss
// Description: Project variables
// ------------------------------------------------------------

$enable-flex:								false;

// Set custom color variables specific to the site
$white:										#FFFFFF;
$black:										#000000;
$lighter-blue:								#A7CADB;
$light-blue:								#428FBE;
$blue:										#2b6a90;
$dark-blue:								#183d53;
$pq-border-blue:						#35769E;
$burgundy:								#5a0b1d;
$gray:										#2e2f30;
$light-gray:                                #474848;
$breadcrumb-gray:						#EFEFEF;
$border-gray:								#E0E0E0;

$navigation-gray:						#DDDDDD;

$font-worksans:							"Work Sans", sans-serif;
$font-ptserif:						    "PT Serif", serif;

/*Social Media Colors for Font Awesome Icons*/
$twitter:							#00aced;
$facebook:							#3b5998;
$googleplus:						#dd4b39;
$pinterest:							#cb2027;
$linkedin:							#007bb6;
$youtube:							#bb0000;
$vimeo:								#aad450;
$instagram:							#517fa4;

$font-family-sans-serif:				$font-worksans;
$font-family-serif:						$font-ptserif;

$link-color:									$blue;