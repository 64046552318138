﻿/// <reference path = "../main.scss" />
// File Name: _footer.scss
// Description: Footer specific or derived styles
// ------------------------------------------------------------

footer {
    background-color: $gray;
    color: $white;
    padding-top: 40px;
    font-family: $font-family-sans-serif;

    &.footer-main {

        .footer-header {
            font-weight: 700;
            font-size: 18px;
            line-height: 1;
            padding-bottom: 1rem;
        }

        > div {
            @include make-container();
            @include make-container-max-widths();

            > div {
                @include make-row();

                .footer-nav {
                    @include make-col(12);
                    @include make-col-ready(12);
                    font-size: 16px;
                    font-weight: 300;

                    @include media-breakpoint-up(md) {
                        @include make-col(4);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(3);
                    }

                    ul {
                        @include list-unstyled();

                        @include media-breakpoint-down(sm) {
                            padding-bottom: 1rem;
                        }

                        li {
                            padding-bottom: .5rem;
                        }

                        a {
                            color: $white;
                            text-decoration: none;
                            &:hover {
                                color: lighten($light-gray, 20%);
                            }
                        }
                    }
                }

                .footer-contact {
                    @include make-col(12);
                    @include make-col-ready(12);

                    @include media-breakpoint-up(md) {
                        @include make-col(8);
                        border-left: 1px solid $light-gray;

                        &:after {
                            content: "";
                            border-right: 1px solid $light-gray;
                            position: absolute;
                            left: 50%;
                            top: 0;
                            bottom: 0;
                            z-index: 100;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(6);
                        border-right: 1px solid $light-gray;
                    }

                    > div {
                        @include make-row();
                        position: relative;

                        @include media-breakpoint-up(lg) {
                        }

                        .location {
                            @include make-col(12);
                            @include make-col-ready(12);

                            @include media-breakpoint-down(sm) {
                                padding-bottom: 2rem;
                            }

                            @include media-breakpoint-up(md) {
                                @include make-col(6);
                                // border-left: 1px solid $light-gray;
                            }

                            .location-info {
                                font-family: $font-family-serif;
                                font-size: 16px;

                                a {
                                    color: $white;
                                    text-decoration: none;
                                    cursor: default;

                                    &:hover {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }

                .footer-social {
                    @include make-col(12);
                    @include make-col-ready(12);

                    @include media-breakpoint-up(md) {
                        @include make-col(12);
                    }

                    @include media-breakpoint-only(md) {
                        text-align: center;
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(3);
                    }

                    > ul {
                        @include list-unstyled();

                        > li {
                            display: inline;

                            a {
                                color: $white;

                                i {
                                    font-size: 26px;
                                }
                                //Web compiler is lame and is not using SASS3.3 so we will have to do this by hand...
                                &:hover {
                                    &.facebook {
                                        i {
                                            color: $facebook;
                                        }
                                    }

                                    &.youtube {
                                        i {
                                            color: $youtube;
                                        }
                                    }

                                    &.linkedin {
                                        i {
                                            color: $linkedin;
                                        }
                                    }

                                    &.googleplus {
                                        i {
                                            color: $googleplus;
                                        }
                                    }

                                    &.twitter {
                                        i {
                                            color: $twitter;
                                        }
                                    }
                                }
                            }

                            + li {
                                margin-left: 15px;
                            }
                        }
                    }
                }

                .footer-copyright {
                    padding: 1rem 0 1.5rem;
                    @include make-col(12);
                    @include make-col-ready(12);
                    text-align: center;

                    .copyright {

                        p {
                            margin-bottom: 0;
                        }
                    }

                    .marathon {
                        font-family: $font-family-serif;

                        a {
                            color: $white;
                            text-decoration: underline;

                            &:hover {
                                color: $light-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}
