﻿///<reference path="../main.scss">

.link-enabled {
    cursor: pointer;
}

.freeze {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.dynamic-form-container {
    display: none;
}

.height-auto {
    height: auto !important;
}

.hidden {
    display: none;
}

@include media-breakpoint-down(md) {
    main {
        position: relative;
    }

    .mobile-header-wrapper {
        float: left;
        width: 100%;
        position: relative;
    }

    .nav-wrapper {
        display: none;
    }

    .mobile-header-phone {
        text-align: center;
        float: left;
        width: 100%;
        position: relative;

        > div {
            width: 50%;
            float: left;
            background-color: $dark-blue;

            &.link-enabled {
                cursor: pointer;
            }

            &:hover {
                background-color: $blue;

                a {
                    color: $white;
                }

                &:last-child {
                    background-color: lighten($burgundy, 10%);
                }
            }

            &:last-child {
                background-color: $burgundy;
                position: absolute;
                margin-left: 50%;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;

                a {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    -moz-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                }
            }

            a {
                float: left;
                color: $white;
                font-size: 18px;
                width: 100%;
                display: inline-block;
                padding: 1rem .5rem;
                text-decoration: none;
                font-family: $font-family-sans-serif;
            }
        }
    }

    .form-inline .form-control {
        width: 87%;
    }

    .mobile-search-form {
        display: none;
    }

    .navbar-toggler {
        font-family: $font-family-sans-serif;
    }

    .navbar-brand {
        padding: 1rem .5rem;
        width: 175px;
    }

    .mobile-header {
        background-color: $gray;
        overflow: auto;

        a {
            color: $gray;
            display: inline-block;
            float: left;
            text-align: center;
            cursor: pointer;
            font-size: 20px;

            &.navbar-home, &.navbar-search-toggler {
                width: 15%;
            }

            &.navbar-utility {
                width: 70%;
                text-transform: uppercase;
                font-size: 12px;
                padding: 9px 0;
                background-color: $gray;
                line-height: 1;
            }

            &.navbar-home {
                background-color: $gray;
            }
        }

        &.tablet {
            a {
                width: 33.33333%;
                text-transform: uppercase;
                font-size: 12px;
                padding: 9px 0;
                line-height: 1;

                &.dark {
                    background: $gray;
                }

                &.light {
                    background: $gray;
                }

                &.navbar-search-toggler {
                    i {
                        font-size: 12px;
                        line-height: 1;
                        padding-left: 5px;
                    }
                }
            }
        }
    }

    .overlay {
        transition: all ease 1s;
        background: rgba(0,0,0,0.0);
        position: absolute;

        &.show {
            max-width: none !important;
            z-index: 198;
            background: rgba(0,0,0,0.8);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            float: left;

            &.out {
                background: rgba(0,0,0,0.0);
            }
        }
    }

    .mobile-nav-search-wrapper {
        padding: 1rem 1rem;
        float: left;
        width: 100%;
        background-color: $gray;

        .mobile-nav-search-input-wrapper {
            position: relative;
            float: left;
            width: 100%;
        }

        input {
            height: 34px;
            border-radius: 0;
            border: none;
            width: 87%;
            float: left;
            background-color: $white;
        }

        .icon {
            cursor: pointer;
            float: right;
            width: 10%;
            background-color: $blue;
            padding: .25rem;
            height: 34px;
            text-align: center;
            display: inline-block;
        }

        i {
            color: $white;
            font-size: 18px;
        }
    }

    #menu {
        z-index: 2000;
        top: 102px;
        left: 0;
        position: absolute;

        @include media-breakpoint-down(sm) {
            top: 161px;
        }
        //This is where the line wraps for VB office
        @media (max-width: 404px) {
            top: 184px;
        }

        &.domReady {
            display: none;
        }

        h2 {
            display: block;
            border-bottom: solid 2px #336ca6;
            color: #052e4d;
            display: table;

            i {
                display: table-cell;
                padding: .2em .2em;
            }

            img {
                float: right;
            }

            a {
                font-size: 1.2em;
                padding-right: 0;
                color: #052e4d;
            }

            display: none;
        }
        /*.multilevelpushmenu_wrapper:before {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $black;
            opacity: .5;
            content: "";
            overflow: visible;
        }*/
        .multilevelpushmenu_wrapper {
            overflow: visible;
            background-color: transparent;

            .ltr {
                box-shadow: none;
            }

            .backItemClass {
                //background-color: $navigation-gray;
                //border-top: 1px solid $navigation-gray;
                padding: 0;
                background: $blue;

                &:hover, &:active {
                    background: lighten($blue, 10%);
                }

                a {
                    text-transform: capitalize;

                    i {
                        padding: 0 .4em 0 0;
                    }
                }
            }

            .levelHolderClass {
                background-color: transparent;

                ul, li, span, a {
                    font-size: 23px;
                    font-weight: 700;
                    font-family: $font-family-sans-serif;
                    color: $white;
                }

                li {
                    background-color: $dark-blue;
                }

                &.scrolled {
                    overflow-y: scroll;
                    height: 100%;
                    margin-right: 3px;

                    ::-webkit-scrollbar {
                        cursor: pointer;
                    }
                }

                > h2 {
                    margin-bottom: 0;
                    display: table;
                    width: 100%;

                    &:nth-child(2) {
                        margin-bottom: -1px;

                        a {
                            padding: .5em 0;
                        }
                    }

                    a {
                        display: table-cell;
                        vertical-align: middle;
                    }

                    display: none;
                }

                &[data-level="0"] {
                    > ul {
                        > li {
                            &:last-child {
                                background-color: $burgundy;
                                border-bottom-color: $burgundy;
                                border-top-color: $burgundy;

                                &:hover {
                                    background-color: lighten($burgundy, 10%);
                                }
                            }
                        }
                    }
                }
                //level 2 menu styles
                /*&[data-level="1"] {
                    li {
                        &:hover, &:active {
                            background-color: lighten($gray, 10%);

                            a {
                                color: $gray;
                            }
                        }

                        background-color: $gray;
                        border-top-color: $gray;

                        &:last-child {
                            border-bottom: 1px solid $gray;
                        }

                        a {
                            text-transform: initial;
                        }
                    }
                }*/
                //level 3 menu styles
                /*&[data-level="2"] {
                    li {
                        &:hover, &:active {
                            background-color: lighten($gray, 10%);

                            a {
                                color: $gray;
                            }
                        }

                        background-color: $gray;
                        border-top-color: $gray;

                        &:last-child {
                            border-bottom: 1px solid $gray;
                        }
                    }
                }*/
            }

            li {
                padding: 0;
                border-top: solid 1px $blue;
                //background-color: $navigation-gray;
                cursor: initial;

                &:first-child {
                    border: none;
                }

                a {
                    padding: 1rem 8px 1rem 1.5rem;
                    text-decoration: none;
                }

                &:hover {
                    background-color: $blue;
                }
            }
            /*li.primary-nav-link {
                //border-top: solid 1px $navigation-gray;
                background-color: $gray;
                text-transform: uppercase;

                &:hover {
                    background-color: lighten($gray, 10%);
                }
            }*/
            /*ul.markup {

                li {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }*/
            a {
                line-height: 1.75rem;
                color: $gray;
                font-size: .93rem;

                i {
                    font-size: 1.5rem;
                    line-height: 1;
                }

                i.floatLeft {
                    float: right; // I realize this doesn't make sense...
                }
            }
        }
    }
}
