﻿/// <reference path = "/main.scss" />
// File Name: _subpage.scss
// Description: Subpage specific or derived styles
// ------------------------------------------------------------

/* work-sans-regular - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/css/fonts/work-sans-v3-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Work Sans'), local('WorkSans-Regular'), url('/css/fonts/work-sans-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/work-sans-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/work-sans-v3-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-300 - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/css/fonts/work-sans-v3-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Light'), local('WorkSans-Light'), url('/css/fonts/work-sans-v3-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/work-sans-v3-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/work-sans-v3-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/css/fonts/work-sans-v3-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Medium'), local('WorkSans-Medium'), url('/css/fonts/work-sans-v3-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/work-sans-v3-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-500.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/work-sans-v3-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/css/fonts/work-sans-v3-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'), url('/css/fonts/work-sans-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/work-sans-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-600.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/work-sans-v3-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/css/fonts/work-sans-v3-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Work Sans Bold'), local('WorkSans-Bold'), url('/css/fonts/work-sans-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/work-sans-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/work-sans-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/work-sans-v3-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* pt-serif-regular - latin */
@font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    src: url('/css/fonts/pt-serif-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('PT Serif'), local('PTSerif-Regular'), url('/css/fonts/pt-serif-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/pt-serif-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/pt-serif-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/pt-serif-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/pt-serif-v9-latin-regular.svg#PTSerif') format('svg'); /* Legacy iOS */
}
/* pt-serif-italic - latin */
@font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: 400;
    src: url('/css/fonts/pt-serif-v9-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('PT Serif Italic'), local('PTSerif-Italic'), url('/css/fonts/pt-serif-v9-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/pt-serif-v9-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/pt-serif-v9-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/pt-serif-v9-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/pt-serif-v9-latin-italic.svg#PTSerif') format('svg'); /* Legacy iOS */
}
/* pt-serif-700 - latin */
@font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 700;
    src: url('/css/fonts/pt-serif-v9-latin-700.eot'); /* IE9 Compat Modes */
    src: local('PT Serif Bold'), local('PTSerif-Bold'), url('/css/fonts/pt-serif-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/css/fonts/pt-serif-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/css/fonts/pt-serif-v9-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/css/fonts/pt-serif-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/css/fonts/pt-serif-v9-latin-700.svg#PTSerif') format('svg'); /* Legacy iOS */
}