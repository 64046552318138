﻿/// <reference path = "../main.scss" />
// File Name: _mixins.scss
// Description: Site specific or derived mixins
// ------------------------------------------------------------

//mixin to create an outline button with a few more options than the original Bootstrap button-outline-variant
@mixin m262-outline-button($txt-color, $bg-color: transparent,$txt-hover: #fff, $bg-hover: $txt-color) {
  color: $txt-color;
  background-image: none;
  background-color: $bg-color;
  border-color: $txt-color;

  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $txt-hover;
    background-color: $bg-hover;
    border-color: $txt-color;
  }
  @include hover {
    color: $txt-hover;
    background-color: $bg-hover;
    border-color: $txt-color;
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: lighten($txt-color, 20%);
    }
    @include hover {
      border-color: lighten($txt-color, 20%);
    }
  }
}

//mixin to create an colored block button with options background color, text color, and active background color
@mixin carousel-indicator-button($txt-color: #fff, $bg-color: transparent,$txt-hover: #fff, $bg-active: $bg-color) {
	color: $txt-color;
	background-color: $bg-color;
	border-color: $bg-color;
	border: none;
	border-radius: 0;
	margin: 0;
	padding: 25px 15px 22px;
	height: auto;
	text-align: center;
	color: $white;
	text-indent: 0;
	font-size: 22px;
	font-weight: 500;

	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		color: $txt-hover;
		background-color: $bg-active;
		border-color: $bg-active;
	}
	@include hover {
		color: $txt-hover;
		background-color: darken($bg-color, 15%);
		border-color: darken($bg-color, 15%);
	}

	&.disabled,
	&:disabled {
		&:focus,
		&.focus {
			border-color: lighten($bg-color, 20%);
		}
	    @include hover {
			border-color: lighten($bg-color, 20%);
	    }
  }
}

//mixin to create rounded-corner button with options for background color, text color, and icon-color
//class should be applied in conjunction with the .btn class
@mixin rounded-corner-button($bg-color: $dark-blue, $border: $bg-color, $txt-color: $white, $arrow-color: $lighter-blue) {
	display: inline-block;
	position: relative;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	color: $txt-color;
	background-color: $bg-color;
	border-color: $border;
	border-radius: 5px;
	line-height: 1;
	padding: 11px 30px 12px 15px;
	font-size: 16px;
	font-weight: 700;
	font-family: $font-family-sans-serif;
	@include transition(all .2s ease-in-out);

	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		color: $txt-color;
		background-color: lighten($bg-color, 15%);
		border-color: lighten($border, 15%);
	}
	@include hover {
		color: $txt-color;
		background-color: lighten($bg-color, 15%);
		border-color: lighten($border, 15%);
	}

	&:after {
		color: $arrow-color;
		//content: "\f054";
		content: "\203A";
		//font-family: FontAwesome;
		font-family: Arial, Helvetica, sans-serif;
		position: absolute;
		right: 13px;
		bottom: 7px;
		margin-left: 10px;
		font-size: 31px;
		font-weight: 400;
	}
}