﻿/// <reference path = "../main.scss" />
// File Name: _nav-primary.scss
// Description: Primary navigation view styles
// ------------------------------------------------------------

nav {

    &.nav-primary {
        position: relative;

        nav {
            @include media-breakpoint-down(md) {
                position: relative;
            }

            .navbar-toggler {
                position: absolute;
                right: 0px;
                top: 50%;
                -moz-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                -o-transform: translate(0, -50%);
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
                color: $dark-blue;
                font-size: 26px;
                height: 100%;
                border-radius: 0;
                outline: 0 !important;
                border: none;

                span {
                    font-size: 20px;
                }

                &.close-menu {
                    -moz-transition: 2s all;
                    -o-transition: 2s all;
                    -webkit-transition: 2s all;
                    transition: 2s all;
                    color: $white;
                    background-color: $dark-blue;
                }
            }

            .nav-wrapper {
                @include make-container();
                @include make-container-max-widths();

                @include media-breakpoint-only(lg) {
                    max-width: none !important;
                    padding: 0 0 0 7.5px;
                }

                #primary-collapsing-navbar {

                    .navbar-nav {
                        @include pull-right();

                        > .nav-item {
                            font-family: $font-family-sans-serif;
                            font-weight: 700;
                            font-size: 18px;

                            @include media-breakpoint-only(lg) {
                                font-size: 16px;
                            }

                            &:hover {
                                background-color: $blue;

                                a, a:after {
                                    color: $white !important;
                                }
                            }

                            .nav-link {
                            }

                            &.megamenu {
                                position: static;

                                &:hover {
                                    > .nav-link {
                                        position: static;

                                        + .dropdown-menu {
                                            left: 0;
                                            right: 0;
                                            height: auto !important;
                                            padding: 1rem 0 !important;

                                            .dropdown-menu {
                                                display: block;
                                                position: relative;
                                                top: 0;
                                                left: 0;
                                                height: auto;

                                                ul {
                                                    li {
                                                        a {
                                                            display: block;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.dropdown {
                                &:hover {
                                    > a.dropdown {
                                        //display: none;
                                        + .dropdown-menu {
                                            border: none;
                                            padding: 0;

                                            .dropdown {
                                                position: static;
                                            }

                                            ul {
                                                padding-left: 10px;

                                                li {
                                                    .dropdown-menu {
                                                        display: block;
                                                        min-width: 200px;
                                                        margin: 0;

                                                        ul {
                                                            padding-left: 10px;

                                                            li {
                                                                a {
                                                                    display: block;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            display: block;
                                        }
                                    }
                                }
                            }

                            + .nav-item {
                                margin-left: 0;
                            }

                            border-left: 1px solid #dadada;
                            border-bottom: none;
                            border-top: none;

                            > .nav-link {
                                color: $dark-blue;
                                padding: 25px 18px 25px 20px;
                                line-height: 20px;

                                @include media-breakpoint-up(lg) {
                                    padding: 33px 5px 25px 8px;
                                }

                                @include media-breakpoint-up(xl) {
                                    padding: 32px 11px 25px 12px;
                                }

                                &:after {
                                    color: $blue;
                                    padding-left: .25rem;
                                }
                            }

                            &.dropdown {

                                > .nav-link {
                                    color: $dark-blue;

                                    &:after {
                                        content: "\f078";
                                        font-family: FontAwesome;
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: $blue;
                                    }
                                }

                                .dropdown-menu {
                                    margin-top: 0px;
                                    background-color: $blue;
                                    border-radius: 0;
                                    min-width: 180px;

                                    ul {
                                        list-style: none outside none;
                                    }

                                    .nav-item {
                                        float: none;

                                        a {
                                            color: $white;
                                            font-weight: 400;
                                            line-height: 20px;
                                        }

                                        &:hover {
                                            text-decoration: underline;
                                        }

                                        + .nav-item {
                                            margin-left: 0;
                                        }
                                    }
                                }

                                &:hover {

                                    > .dropdown-menu {
                                        display: block;
                                    }
                                }
                            }

                            &:last-child {
                                border-left: none;

                                .nav-link {
                                    padding-top: 32px;

                                    @include media-breakpoint-up(lg) {
                                        padding-bottom: 26px;
                                    }
                                }

                                &:hover {
                                    .nav-link {
                                        background-color: lighten($burgundy, 10%);
                                    }
                                }

                                > .nav-link {
                                    background-color: $burgundy;
                                    color: $white;
                                    border-left: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
