﻿/// <reference path = "../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

img.img-right {
    @include pull-right();
    max-width: 50% !important;
    height: auto !important;
    margin-left: 10px;
    margin-bottom: 10px;
}

img.img-left {
    @include pull-left();
    max-width: 50% !important;
    height: auto !important;
    margin-right: 10px;
    margin-bottom: 10px;
}

div.img-right, p.img-right, span.img-right {
    @include pull-right;
    max-width: 50%;
    height: auto;
    margin-left: 15px;
    margin-bottom: 10px;

    br {
        display: none;
    }

    > img {
        @include img-fluid();
    }

    .img-caption, & + .img-caption {
        display: block;
        text-align: right;
        font-family: $font-family-serif;
        font-style: italic;
        font-size: 14px;
    }
}

div.img-left, p.img-left, span.img-left {
    @include pull-left;
    max-width: 50%;
    height: auto;
    margin-right: 15px;
    margin-bottom: 10px;

    br {
        display: none;
    }

    > img {
        @include img-fluid();
    }

    .img-caption, & + .img-caption {
        display: block;
        text-align: left;
        font-family: $font-family-serif;
        font-style: italic;
        font-size: 14px;
    }
}

h1, .h1, .page-heading {
    font-family: $font-family-sans-serif;
    color: $blue;
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 25px;

    &.center {
        text-align: center;
    }
}

h2, .h2, .header {
    font-family: $font-family-sans-serif;
    color: $dark-blue;
    font-size: 24px;
    font-weight: 700;
}

p + h2, p + .h2, p + .header {
    padding-top: 15px;
}

h3, .h3, .subheader {
    font-family: $font-family-sans-serif;
    color: $gray;
    font-size: 18px;
    font-weight: 700;
}

img + .caption {
    text-align: right;
    font-family: $font-family-serif;
    font-style: italic;
    font-size: 14px;
}


.col-md-8 + .col-md-4 {
    padding-left: 0;

    img {
        float: right;
    }
}

.col-md-4 + .col-md-8 {
    padding-left: 0;

    img {
        float: left;

        .caption {
            text-align: left;
        }
    }
}

.grid-btn {
    @include rounded-corner-button();
    float: left;
    clear: left;

    @include media-breakpoint-up(md) {
        float: none;
    }

    &.btn-burgundy {
        @include rounded-corner-button($burgundy, $white, $white);
    }

    + .grid-btn {
        margin-top: 12px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-left: 20px;
        }
    }
}

p {
    font-family: $font-family-serif;
    font-size: 16px;
}

.visible-md-only {
    @include media-breakpoint-only(md) {
        display: block !important;
    }
}

.location-xl {
    @include media-breakpoint-up(xl) {
        display: inline !important;
    }
}

.link-enabled {
    cursor: pointer;
}

.addthis_inline_share_toolbox {
    clear: none !important;
}

.title-wrap {
    float: left;
    width: 100%;
    padding-bottom: 25px;

    h1 {
        float: left;
        width: 75%;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        + #addthisbox {
            float: right;
            width: 25%;
            text-align: right;

            @include media-breakpoint-down(md) {
                width: 100%;
                text-align: left;
                float: left;
                padding-top: 1rem;
            }
        }
    }
}
