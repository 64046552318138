﻿/// <reference path = "../main.scss" />
// File Name: _home.scss
// Description: Homepage specific or derived styles
// ------------------------------------------------------------

.home {
    .container-fluid {
        padding: 0;
    }

    .page-columns {
        padding: 1.5rem .9375rem 3rem;

        .img-right, .img-left {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .sidebar-content {
            display: none;
        }

        @include clearfix;
    }
}

.callouts {
    background-color: #F0F0F0;
    padding: 2.5rem 0;

    > div {
        @include make-container();
        @include make-container-max-widths();

        > div {
            @include make-row();

            .callout {
                @include make-col(12);
                @include make-col-ready(12);

                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }

                @include media-breakpoint-down(sm) {
                    padding-bottom: 2rem;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    text-align: center;
                }

                > div {
                    position: relative;

                    &.link-enabled {
                        display: inline-block;

                        &:before {
                            opacity: 0;
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            -webkit-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out;
                            background-color: rgba(0,0,0,0.4);
                        }

                        &:hover {

                            &:before {
                                opacity: 1;
                            }

                            .callout-label {
                                border-bottom-color: $white;
                            }
                        }
                    }

                    img {
                        @include img-fluid();
                        margin: 0 auto;
                        float: left;
                        width: 100%;
                    }

                    .callout-label {
                        position: absolute;
                        left: 26px;
                        bottom: 30px;
                        border-bottom: 1px solid transparent;
                        -moz-transition: all .5s ease-in-out;
                        -o-transition: all .5s ease-in-out;
                        -webkit-transition: all .5s ease-in-out;
                        transition: all .5s ease-in-out;

                        @include media-breakpoint-only(md) {
                            left: 15px;
                            bottom: 20px;
                        }

                        a {
                            color: $white;
                            font-size: 24px;
                            font-family: $font-family-sans-serif;
                            font-weight: 700;
                            text-decoration: none;

                            &:after {
                                content: "\203A";
                                font-family: Arial, Helvetica, sans-serif;
                                right: 0;
                                bottom: 0;
                                margin-left: 10px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }

            .button-bar {
                @include make-col(12);
                @include make-col-ready(12);
                margin-top: 30px;
                text-align: center;

                .btn {
                    @include rounded-corner-button();
                    display: inline-block;

                    + .btn {
                        margin-top: 12px;

                        @include media-breakpoint-up(md) {
                            margin-top: 0;
                            margin-left: 20px;
                        }
                    }
                }

                @media (max-width: 400px) {
                    text-align: left;
                    padding-right: 0;

                    .btn {
                        font-size: 15px;
                        padding-right: 15px;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.hero-image {
    color: $white;
    position: relative;
    width: 100%;
    padding-top: 70.948%;
    background-color: $white;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    font-family: $font-family-sans-serif;

    &.mobile-Left {
        @include media-breakpoint-down(sm) {
            background-position: center left;
        }
    }

    &.mobile-Center {
        @include media-breakpoint-down(sm) {
            background-position: center center;
        }
    }

    &.mobile-Right {
        @include media-breakpoint-down(sm) {
            background-position: center right;
        }
    }

    @include media-breakpoint-up(sm) {
        padding-top: 60.948%;
    }

    @media (min-width: 650px) {
        padding-top: 50%;
    }

    @include media-breakpoint-up(md) {
        padding-top: 35.948%;
    }

    @include media-breakpoint-up(lg) {
    }

    @include media-breakpoint-up(xl) {
        padding-top: 29.948%;
    }

    .hero-caption {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 137px;

        @include media-breakpoint-up(md) {
            bottom: 117px;
        }

        @include media-breakpoint-up(lg) {
            bottom: 99px;
        }

        @include media-breakpoint-up(xl) {
            bottom: 97px;
        }

        &.link-enabled {
            &:hover {
                .hero-btn {
                    background-color: rgba(0,0,0,0.3);
                }
            }
        }

        .hero-caption-wrapper {
            @include make-container();
            @include make-container-max-widths();
            position: absolute;
            top: 50%;
            left: 0;
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            text-align: center;

            @include media-breakpoint-up(sm) {
                left: 50%;
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            @include media-breakpoint-up(sm) {
                width: 90%;
            }

            @include media-breakpoint-up(lg) {
                width: 80%;
            }

            .caption {
                font-weight: 700;
                line-height: 26px;
                font-size: 26px;

                > div {
                    padding-top: 2rem;

                    @media (max-width: 350px) {
                        padding-top: 1rem;
                    }

                    @include media-breakpoint-up(md) {
                        padding-top: 1rem;
                    }
                }

                @include media-breakpoint-up(sm) {
                    font-size: 36px;
                    line-height: 36px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 44px;
                    line-height: 46px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 48px;
                    line-height: 50px;
                }

                .hero-btn {
                    display: inline-block;
                }
            }
        }
    }

    .hero-cta {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($dark-blue, 0.8);

        @include media-breakpoint-up(lg) {
            position: absolute;
        }
    }
}

.hero-cta {
    color: $white;
    background-color: $dark-blue;
    padding: 15px .9375rem;

    @include media-breakpoint-up(lg) {
        padding: 28px 0;
    }

    &.link-enabled {
        &:hover {
            .hero-btn {
                background-color: rgba(0,0,0,0.3);
            }
        }
    }

    .cta-wrapper {
        @include make-container();
        @include make-container-max-widths();

        .cta-text {
            //font-family: $font-family-sans-serif;
            font-weight: 300;
            float: left;
            font-size: 16px;
            text-align: center;
            line-height: 20px;

            i {
                display: none;
            }

            @include media-breakpoint-up(sm) {
                font-size: 20px;
                line-height: 24px;
            }

            @include media-breakpoint-up(md) {
                font-size: 19px;
                line-height: 1.5;

                i {
                    display: inline-block;
                }
            }

            @include media-breakpoint-up(xl) {
                font-size: 24px;
                text-align: left;
            }

            .cta-lead {
                font-weight: 700;
            }
        }

        .cta-link {
            text-align: center;

            .btn {
                margin-top: 1rem;

                @include media-breakpoint-up(lg) {
                    position: relative;
                    //top: -9px;
                    margin-top: 0;
                }
            }

            @include media-breakpoint-up(lg) {
                float: right;
            }
        }
    }
}

.hero-btn {
    @include rounded-corner-button(transparent, $white, $white, $white);
    border-width: 2px;
}

.mobileContent {
    > div {
        @include make-container();
        @include make-container-max-widths();

        > div {
            @include make-row();
        }
    }

    padding: 2rem 0;

    h3 {
        padding-bottom: .5rem;
    }

    .clearfix + .office {
        padding-top: 3rem;
    }
}

.mapWrapper {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
        height: 400px;
    }

    @include media-breakpoint-up(lg) {
        height: 500px;
    }
}

.homepage-testimonial {
    background-color: $blue;
    padding: 2.5rem 0;
    color: $white;

    h2, .h1.center {
        color: $white;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }

    > div {
        @include make-container();
        @include make-container-max-widths();

        > div {
            @include make-row();

            .testimonial-quote {
                @include make-col(12);
                @include make-col-ready(12);
                font-family: $font-family-serif;
                font-size: 22px;
                color: $white;


                @include media-breakpoint-up(md) {
                    text-align: center;
                }

                &:before, &:after {
                    position: absolute;
                    top: 25%;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                &:before {
                    left: 0;
                    content: url(../../images/left-quote.png);
                }

                &:after {
                    right: 0;
                    content: url(../../images/right-quote.png);
                }

                p {
                    padding: 0 4.5rem;
                    font-size: 22px;

                    @include media-breakpoint-down(sm) {
                        padding: 0;
                    }
                }

                .testimonial-credit {
                    font-family: $font-family-sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }
    }
}
