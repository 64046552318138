﻿/// <reference path = "../main.scss" />
// File Name: _button.scss
// Description: Button styles
// ------------------------------------------------------------

.teamMemberContainer {
    .team-members {
        @include make-col(12);
        @include make-col-ready(1);
        padding-left: 0;
        padding-right: 0;

        .btn.url {
            margin-top: 10px;
            margin-bottom: 10px;
            @include rounded-corner-button();

            &:hover {
                text-decoration: none;
            }
        }

        > div {
            @include make-row();
        }

        .team-member-wrap {
            @include make-col-ready(1);
            @include make-col(12);

            &.image {
                float: none;
                width: auto;
                left: 50%;
                -moz-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                -o-transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
            }

            display: inline-block;

            @include media-breakpoint-up(sm) {
                @include make-col(6);

                &.image {
                    @include make-col(6);
                    left: 0;
                    transform: none;
                }
            }

            @include media-breakpoint-up(lg) {
                @include make-col(4);

                &.image {
                    @include make-col(4);
                }
            }
        }

        .team-member {
            @include make-col(12);
            padding-bottom: 2rem;

            div, a {
                font-family: $font-family-serif;
                color: $black;
                font-size: 16px;
            }

            a {
                color: $blue;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                @include img-fluid();
            }

            .team-member-name {
                font-size: 18px;
                color: $gray;
                font-weight: 700;
                font-family: $font-family-sans-serif;
                padding-top: 5px;
            }
        }
    }
}
