﻿/// <reference path = "../main.scss" />
// File Name: _subpage.scss
// Description: Subpage specific or derived styles
// ------------------------------------------------------------

.contour {
    label {
        font-family: $font-family-sans-serif;
    }
}

.subpage {
    &.errorPage {
        .content-area {
            min-height: 400px;
        }
    }

    article {
        li {
            font-family: $font-family-serif;
        }
    }

    a {
        text-decoration: none;
        color: $blue;

        &:hover {
            color: lighten($blue, 10%);
        }
    }

    .caption {
        margin-bottom: 0;
    }

    .column-8 {
        @include make-col(8);
        @include make-col-ready(8);
    }

    .column-4 {
        @include make-col(12);
        @include make-col-ready(12);
    }

    .hero-cta {
        padding: 11px 0;

        @include media-breakpoint-down(sm) {
            padding: 15px;
        }

        .cta-wrapper {
            position: relative;

            .cta-text {
                font-family: $font-family-sans-serif;
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: .9375rem;
                -moz-transform: inherit;
                -ms-transform: inherit;
                -o-transform: inherit;
                -webkit-transform: inherit;
                transform: translate(0, -50%);

                @include media-breakpoint-down(md) {
                    position: relative;
                    font-size: 18px;
                    top: 0;
                    transform: none;
                    left: 0;
                }

                @include media-breakpoint-down(sm) {
                    left: 0;
                    font-size: 17px;
                }
            }

            .cta-link {
                .btn {
                    top: 0;
                    color: white;

                    @include media-breakpoint-down(sm) {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    #breadcrumbs {
        background-color: $breadcrumb-gray;
        border-top: 1px solid $border-gray;
        border-bottom: 1px solid $border-gray;
        line-height: 1;
        font-family: $font-family-sans-serif;

        > div {
            @include make-container();
            @include make-container-max-widths();

            .breadcrumb {
                font-size: 14px;
                font-weight: 400;
                padding: 0.75rem 0;
                margin-bottom: 0;

                .breadcrumb-item {

                    a {
                        color: $blue;
                        text-decoration: underline;
                    }

                    &.active {
                        color: $gray;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .content-area {
        margin-top: 60px;
        margin-bottom: 60px;

        @include media-breakpoint-down(sm) {
            padding-left: .9375rem;
            padding-right: .9375rem;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &.short-bottom {
            margin-bottom: 45px;
        }

        > div {
            @include make-container(0);
            @include make-container-max-widths();

            > div {
                @include make-row();

                .main-content {
                    > .container {
                        padding: 0;
                    }
                }

                article {
                    @include make-col(12);
                    @include make-col-ready(12);

                    @include media-breakpoint-up(md) {
                        @include make-col(8);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(9);
                    }

                    > .container {
                        padding: 0;
                    }

                    p {
                        color: $black;
                    }

                    img + h1, .caption + h1, img + .title-wrap, .caption + .title-wrap {
                        margin-top: 35px;
                    }

                    h1, .h1, .heading {
                        font-family: $font-family-sans-serif;
                        font-weight: 300;
                        font-size: 36px;
                        color: $blue;
                    }

                    h2, .h2 {
                        font-family: $font-family-sans-serif;
                        font-weight: 700;
                        font-size: 24px;
                        color: $dark-blue;
                        margin-bottom: 20px;
                    }

                    h3, .h3, .subheader {
                        font-family: $font-family-sans-serif;
                        font-weight: 700;
                        font-size: 18px;
                        color: $gray;
                    }

                    p + h3, p + .h3, p + .subheader, p + h2, p + .h2 {
                        margin-top: 25px;
                    }

                    span.videoWrapper {
                        display: block;
                    }

                    .videoWrapper {
                        position: relative;
                        padding-bottom: 56.25%;
                        padding-top: 25px;
                        height: 0;

                        &.map {
                            -moz-box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
                            -webkit-box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
                            box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
                            margin-bottom: 2rem;

                            @include media-breakpoint-up(md) {
                                margin-bottom: 4rem;
                            }

                            iframe {
                                border: none;
                            }
                        }

                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .pull-quote {
                        @include make-col(4);
                        @include make-col-ready(4);
                        float: right;
                        background-color: $blue;
                        font-family: $font-family-sans-serif;
                        color: $white;
                        margin-top: 4px;
                        padding: 27px;
                        margin-left: 10px;
                        margin-bottom: 10px;

                        @include media-breakpoint-up(sm) {
                            &.clone {
                                display: none;
                            }
                        }

                        @include media-breakpoint-down(lg) {
                            padding: 20px;
                        }

                        @include media-breakpoint-down(md) {
                            padding: 14px;
                        }

                        @include media-breakpoint-down(xs) {
                            display: none;

                            &.clone {
                                display: block;
                                float: none;
                                margin-left: 0;
                                width: 100%;
                            }
                        }


                        .pull-quote-header {
                            font-weight: 400;
                            font-size: 14px;
                            text-transform: uppercase;
                        }

                        .pull-quote-lead {
                            font-weight: 300;
                            font-size: 36px;
                            border-top: 1px solid $pq-border-blue;
                            margin-top: 18px;
                            padding: 5px 0 0;

                            @include media-breakpoint-down(lg) {
                                font-size: 30px;
                            }

                            @include media-breakpoint-down(md) {
                                font-size: 23px;
                                margin-top: 12px;
                            }
                        }

                        .pull-quote-summary {
                            font-weight: 700;
                            font-size: 18px;
                            color: $lighter-blue;

                            @include media-breakpoint-down(lg) {
                                font-weight: 600;
                            }

                            @include media-breakpoint-down(md) {
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }

                    blockquote {
                        font-size: 22px;
                        font-weight: 400;
                        color: $blue;
                        line-height: 28px;

                        > p {
                            color: inherit;
                            font-size: inherit;
                            margin-bottom: 0;

                            &:before {
                                content: open-quote;
                                display: inline;
                            }

                            &:after {
                                content: close-quote;
                                display: inline;
                            }
                        }

                        .quote-credit {
                            font-family: $font-family-sans-serif;
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                    }

                    .container {
                        .row {
                            > div {
                                > div.subheader {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }

                    .additional-photos {
                        padding-top: 20px;

                        > div {
                            @include make-row();

                            > div {
                                @include make-col(6);
                                @include make-col-ready(6);
                                padding-bottom: 1rem;

                                @include media-breakpoint-up(md) {
                                    @include make-col(4);
                                }

                                > .gallery-image {
                                    position: relative;
                                    cursor: pointer;
                                    display: inline-block;

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        height: 100%;
                                        width: 100%;
                                        background: rgba(0,0,0,0.3);
                                        opacity: 0;
                                        transition: opacity .5s ease;
                                        pointer-events: none;
                                    }

                                    &:hover {
                                        &:after {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .office-location {
                        > div {
                            @include make-container();
                            @include make-container-max-widths();

                            > div {
                                @include make-row();

                                > .location-address {
                                    @include make-col(4);
                                    @include make-col-ready(4);
                                    @include make-col-push(8);
                                    padding-right: 0;

                                    .h2 {
                                        display: block;
                                    }

                                    a {
                                        color: $gray;

                                        @include media-breakpoint-down(sm) {
                                            @include rounded-corner-button();
                                            margin-top: .5rem;
                                        }
                                    }

                                    @include media-breakpoint-down(lg) {
                                        @include make-col(5);
                                        @include make-col-push(7);
                                    }

                                    @include media-breakpoint-down(md) {
                                        @include make-col(12);
                                        left: 0;
                                        padding-left: 0;
                                    }
                                }

                                > .location-map {
                                    @include make-col-ready(8);
                                    @include make-col(8);
                                    @include make-col-pull(4);
                                    padding-left: 0;

                                    @include media-breakpoint-down(lg) {
                                        @include make-col(7);
                                        @include make-col-pull(5);
                                    }

                                    @include media-breakpoint-down(md) {
                                        @include make-col(12);
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                aside {
                    @include make-col(12);
                    @include make-col-ready(12);
                    margin-top: 25px;

                    @include media-breakpoint-up(md) {
                        @include make-col(4);
                        margin-top: 0;
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(3);
                    }

                    .widget {
                        border-left: 1px solid $border-gray;
                        padding-left: 15px;
                        margin-bottom: 60px;
                        font-family: $font-family-sans-serif;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 25px;
                        }

                        .widget-header {
                            font-family: $font-family-sans-serif;
                            font-weight: 700;
                            font-size: 17px;
                            color: $gray;
                            line-height: 1;
                        }

                        &.locations {

                            .location {
                                padding-top: 18px;
                                margin-bottom: 10px;

                                .location-name {
                                    font-size: 16px;
                                    font-weight: 700;
                                    color: $blue;
                                    padding-bottom: 8px;
                                }

                                .btn-wrapper {
                                    padding-top: 8px;
                                }

                                .address {
                                    font-family: $font-family-serif;

                                    a {
                                        color: $gray;

                                        &:hover {
                                            color: $blue;
                                        }
                                    }

                                    .phone {

                                        a {
                                            color: $gray;
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }

                        &.related-services {

                            .services {
                                @include list-unstyled();

                                > li {
                                    font-family: $font-family-sans-serif;
                                    margin-top: 22px;

                                    a {

                                        &:hover {
                                            color: $light-blue;
                                        }
                                    }
                                }
                            }
                        }

                        &.blue-widget {
                            border-left: 1px solid transparent;
                            background-color: $dark-blue;
                            color: $white;
                            padding: 18px;
                            font-weight: 300;

                            @include media-breakpoint-down(lg) {
                                padding: 15px;
                            }

                            @include media-breakpoint-down(sm) {
                                display: inline-block;
                            }

                            @include media-breakpoint-down(xs) {
                                display: block;
                            }

                            &.link-enabled {
                                &:hover {
                                    .widget-link {
                                        .btn, .btn:after {
                                            color: $lighter-blue;
                                            border-color: $lighter-blue;
                                        }
                                    }
                                }
                            }

                            .widget-header {
                                color: $white;
                                margin-bottom: 32px;

                                > i {
                                    font-size: 23px;
                                }

                                @include media-breakpoint-down(lg) {
                                    i {
                                        display: none;
                                    }
                                }
                            }

                            > ol, ul {
                                padding-left: 18px;
                            }

                            .widget-link {
                                text-align: center;

                                @include media-breakpoint-down(sm) {
                                    text-align: left;
                                }

                                .btn {
                                    @include rounded-corner-button(transparent, $white, $white, $white);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    aside {
        section {
            > .container {
                padding: 0;
            }
        }

        .btn.sidebar-btn {
            @include rounded-corner-button(transparent, $pq-border-blue, $pq-border-blue, $pq-border-blue);

            &:hover {
                color: #fff;
                border-color: transparent;
                background-color: $dark-blue;

                &:after {
                    color: #fff;
                }
            }
        }
    }

    .contour.contact {
        label.fieldLabel {
            float: left;
            width: 20%;
            position: relative;
            top: 12px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        input, textarea {
            max-width: none !important;
            float: left;
            width: 80%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        fieldset {
            padding-right: 0;
            padding-top: 0;
        }

        .recaptcha {
            label {
                display: none;
            }

            #recaptcha_widget_div {
                float: right;
                padding-top: 1rem;
            }
        }

        .contourFieldSet {
            .col-md-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .contourNavigation {
            text-align: right;

            > div {
                padding-right: 0;
            }

            .btn {
                @include rounded-corner-button();
                width: auto;
                text-align: center;
                float: right;

                &:after {
                    color: $white;
                }
            }
        }
    }
}
